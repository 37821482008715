<template>
  <div class="btn-group">
    <input
      type="text"
      @click.stop="toggleMenu()"
      @blur="toggleMenuLater()"
      class="dropdown-toggle"
      v-bind:placeholder="placeholderText1"
      @keyup="filtrarOpciones"
      ref="dropdownInput"
      v-show="mostrarInput"
    />
    <input
      type="button"
      style="text-align: left"
      @click.stop="toggleMenu()"
      @blur="toggleMenuLater()"
      class="dropdown-toggle"
      v-bind:placeholder="placeholderText2"
      @keyup="filtrarOpciones"
      ref="dropdownInput2"
      v-show="!mostrarInput"
      :value="placeholderText2"
    />
    <span class="caret"></span>
    <img class="dropdown-caret" src="../assets/img/icon/caret.png" />

    <!-- <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOptions[this.name] === undefined">
      <span class="caret"></span>
    </li>-->

    <ul
      class="dropdown-menu"
      v-if="showMenu"
      v-bind:class="{ 'dropdown-menu-up': paraArriba }"
    >
      <li
        v-for="option in opcionesFiltradas"
        :key="option.id"
        :id="option.id"
        @click.stop="updateOptions(option)"
      >
        <a href="javascript:void(0)">{{ option[getName] }}</a>
        <img src="../assets/img/check_blue.png" v-show="elegido(option)" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOptions: [],
      showMenu: false,
      opcionesFiltradas: null,
      placeholderText1: "",
      placeholderText2: "",
      puedeCerrar: true,
      mostrarInput: false,
    };
  },
  props: {
    options: {
      type: [Array, Object],
    },
    selecteds: [],
    placeholder: [String],
    usarSelected: [Boolean],
    paraArriba: [Boolean],
    name: [String],
    conInput: [Boolean],
    conLabel: [Boolean],
  },
  computed: {
    getName() {
      if (this.name != null) return this.name;
      else return "name";
    },
  },
  mounted() {
    this.opcionesFiltradas = this.options;
    this.mostrarInput = this.conInput;
    if (this.placeholder) {
      this.placeholderText1 = this.placeholderText1.concat(this.placeholder);
      this.placeholderText2 = this.placeholderText2.concat(this.placeholder);
    }
  },
  created() {},
  methods: {
    init() {
      this.selectedOptions = [];
      this.selectedOptions = this.selectedOptions.concat(this.options);
    },
    cerrarOtros(that) {
      if (!(this == that)) this.showMenu = false;
    },
    updateOptions(option) {
      this.puedeCerrar = false;
      if(option[this.name] == "Todas" || option[this.name] == "Todos"){
        this.selectedOptions = this.options.filter(o => o[this.name] != "Todas" && o[this.name] != "Todos")
      }else{
        var name_temp = this.name;
        const index = this.selectedOptions.findIndex(
          (x) => x[name_temp] === option[name_temp]
        );
        if (index !== undefined && index != -1) {
          this.selectedOptions.splice(index, 1);
        } else {
          this.selectedOptions.push(option);
        }
      }

      this.placeholderText1 = this.selectedOptions.length + " seleccionados";
      this.placeholderText2 = this.selectedOptions.length + " seleccionados";
      this.$emit("updateOptions", this.selectedOptions);
    },
    toggleMenu() {
      // this.$eventHub.$emit("dropdown-cerrar", this);
      this.showMenu = !this.showMenu;
      this.opcionesFiltradas = this.options;
      if (this.conLabel == true && this.mostrarInput == false) {
        this.puedeCerrar = false;
        this.mostrarInput = true;
        this.$nextTick(() => {
          if (this.mostrarInput) {
            this.$refs.dropdownInput.focus();
          } else {
            this.$refs.dropdownInput2.focus();
          }
        });
      }
      this.placeholderText1 = this.selectedOptions.length + " seleccionados";
      this.placeholderText2 = this.selectedOptions.length + " seleccionados";
    },
    toggleMenuLater() {
      var that = this;
      setTimeout(function () {
        if (that.puedeCerrar == true) {
          // that.$eventHub.$emit("dropdown-cerrar", that);
          that.showMenu = false;
          that.opcionesFiltradas = that.options;
          if (that.conLabel == true) {
            that.mostrarInput = that.showMenu;
          }
        } else {
          that.puedeCerrar = true;
          that.$nextTick(() => {
            if (that.mostrarInput) {
              that.$refs.dropdownInput.focus();
            } else {
              that.$refs.dropdownInput2.focus();
            }
          });
        }
      }, 150);
    },
    filtrarOpciones(el) {
      this.opcionesFiltradas = [];
      var that = this;
      this.options.forEach(function (item) {
        if (
          item[that.getName]
            .toUpperCase()
            .indexOf(el.target.value.toUpperCase()) > -1
        )
          that.opcionesFiltradas.push(item);
      });
    },
    resetDropwdown() {
      this.selectedOptions = [];
      this.$refs.dropdownInput.value = "";
      this.$refs.dropdownInput2.value = "";
      this.$emit("updateOptions", this.selectedOptions);
    },
    elegido(option) {
      this.placeholderText1 = this.selectedOptions.length + " seleccionados";
      var name_temp = this.name;
      return this.selectedOptions.some(
        (x) => x[name_temp] === option[name_temp]
      );
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/components/dropdown.scss"></style>
