<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>Info Usuario</label>
        <img src="../../../assets/img/icon/close_popup.png" @click="cerrar()" />
        <div class="popup-top-btn-container">
          <div
            class="popup-top-btn"
            @click="mostrarEliminar = true"
            v-if="$usuario != null && $usuario.rol.some((r) => r == 'admin')"
          >
            <img src="../../../assets/img/popup/delete.png" />
          </div>
          <div class="popup-top-btn" @click="editar()" v-if="puedeEditar()">
            <img src="../../../assets/img/popup/edit.png" />
          </div>
        </div>
      </div>
      <div class="popup-body popup-body-scroll">
        <div class="popup-row">
          <label class="popup-label-info">Nombre</label>
          <label>{{ usuario.nombre }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Apellido</label>
          <label>{{ usuario.apellido }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Email</label>
          <label>{{ usuario.email }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">DNI</label>
          <label>{{ usuario.dni }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Posición</label>
          <label>{{ getRolesText() }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Empresas</label>
          <label>{{ getEmpresasText() }}</label>
        </div>
        <div class="popup-row">
          <label class="popup-label-info">Vigilancias</label>
          <label>{{ getVigilanciasText() }}</label>
        </div>
      </div>
    </div>
    <Eliminar
      :mostrar="mostrarEliminar"
      :tipo="'Usuario'"
      :nombre="usuario.nombre + ' ' + usuario.apellido"
      @cerrar="eliminarCerrar()"
      @aceptar="eliminarAceptar()"
    ></Eliminar>
  </div>
</template>

<script>
import Eliminar from "@/components/Eliminar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Info_Usuario",
  components: { Eliminar },
  props: {
    usuario: Object,
    empresas: Array,
    vigilancias: Array,
  },
  data() {
    return {
      mostrarEliminar: false,
    };
  },
  computed: {
    ...mapGetters({
      $usuario: "usuario",
    }),
  },
  mounted() {},
  methods: {
    puedeEditar() {
      if (this.$usuario.rol.some((r) => r == "admin")) {
        return true;
      } else if (
        this.$usuario.rol.some((r) => r == "supervisor_general") &&
        this.usuario.rol.some((r) => r == "intendente" || r == "supervisor")
      ) {
        return true;
      } else if (
        this.$usuario.rol.some((r) => r == "intendente") &&
        this.usuario.rol.some((r) => r == "supervisor")
      ) {
        return true;
      } else {
        return false;
      }
    },
    editar() {
      this.$emit("editar", this.usuario);
      this.cerrar();
    },
    cerrar() {
      this.$emit("cerrar");
    },
    eliminarCerrar() {
      this.mostrarEliminar = false;
    },
    eliminarAceptar() {
      this.mostrarEliminar = false;
      var that = this;
      this.$axios
        .delete(this.$serverURL + "/api/user/" + this.usuario.id + "/")
        .then(function (response) {
          console.log(response);
          that.$emit("get");
          that.cerrar();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getRolesText() {
      let respuesta = "";
      if (this.usuario.rol != null) {
        this.usuario.rol.forEach((p) => {
          let rol = p.split("_").join(" ");
          respuesta =
            respuesta + rol[0].toUpperCase() + rol.substring(1) + ", ";
        });
      }
      return respuesta.substr(0, respuesta.length - 2);
    },
    getEmpresasText() {
      if (this.usuario.empresas.length == this.empresas.length - 1) {
        return "Todas las empresas";
      } else {
        let result = "";
        this.usuario.empresas.forEach((e) => {
          result = result + e.nombre + ", ";
        });
        return result.substr(0, result.length - 2);
      }
    },
    getVigilanciasText() {
      if (this.usuario.vigilancias.length == this.vigilancias.length) {
        return "Todas las vigilancias";
      } else {
        let result = "";
        if (this.usuario.vigilancias.length > 0) {
          this.usuario.vigilancias.forEach((e) => {
            result = result + e.nombre + ", ";
          });
          result = result.substr(0, result.length - 2);
        } else {
          result = "-";
        }
        return result;
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss"></style>
