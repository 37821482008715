<template>
  <div class="popup-container">
    <div class="popup-mask"></div>
    <div class="popup">
      <div class="popup-top">
        <label>{{ titulo }}</label>
        <img
          src="../../../assets/img/icon/close_popup.png"
          @click="mostrarSalir = true"
        />
      </div>
      <div class="popup-body">
        <div v-show="seccion == 1">
          <div class="popup-row">
            <label>Tipo de usuario (permisos) (*)</label>
            <Dropdown
              class="select"
              :options="permisos"
              :placeholder="'Selecciona'"
              :paraArriba="false"
              :conInput="false"
              :name="'name'"
              ref="dropdownPermiso"
              v-on:updateOption="updateDropdownPermiso"
              :class="{ 'error-dropdown': errorPermiso }"
            ></Dropdown>
          </div>
          <div class="popup-row">
            <label>Empresa/s (*)</label>
            <DropdownMultiselect
              class="select"
              :options="empresas"
              :placeholder="'Selecciona'"
              :paraArriba="false"
              :conInput="false"
              :name="'nombre'"
              ref="dropdownEmpresa"
              v-on:updateOptions="updateDropdownEmpresa"
              :class="{ 'error-dropdown': errorEmpresa }"
            >
            </DropdownMultiselect>
          </div>
          <div class="popup-row">
            <label>Vigilancia/s (*)</label>
            <DropdownMultiselect
              class="select"
              :options="vigilancias"
              :placeholder="'Selecciona'"
              :paraArriba="false"
              :conInput="false"
              :name="'nombre'"
              ref="dropdownVigilancia"
              v-on:updateOptions="updateDropdownVigilancia"
              :class="{ 'error-dropdown': errorVigilancia }"
            >
            </DropdownMultiselect>
          </div>
        </div>
        <div v-show="seccion == 2">
          <div class="popup-row">
            <label>Nombre (*)</label>
            <div
              class="input input-primary"
              :class="{ 'input-error': errorNombre }"
            >
              <input
                type="text"
                placeholder="Escribe nombre del usuario"
                v-model="nombre"
              />
            </div>
          </div>
          <div class="popup-row">
            <label>Apellido (*)</label>
            <div
              class="input input-primary"
              :class="{ 'input-error': errorApellido }"
            >
              <input
                type="text"
                placeholder="Escribe apellido del usuario"
                v-model="apellido"
              />
            </div>
          </div>
          <div class="popup-row">
            <label>Email (*)</label>
            <label class="popup-error" v-show="errorEmail != ''">{{
              errorEmail
            }}</label>
            <div
              class="input input-primary"
              :class="{ 'input-error': errorEmail != '' }"
            >
              <input
                type="text"
                placeholder="Escribe email del usuario"
                v-model="email"
              />
            </div>
          </div>
          <div class="popup-row">
            <label>DNI (*)</label>
            <div
              class="input input-primary"
              :class="{ 'input-error': errorDNI }"
            >
              <input
                type="text"
                placeholder="Escribe DNI del usuario"
                maxlength="9"
                v-model="dni"
                @keyup="onlyNumbers"
                @keypress="onlyNumbersPress"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="popup-bot">
        <div class="popup-bot-selector-container">
          <div
            class="popup-bot-selector"
            :class="{ 'popup-bot-selector-active': seccion == 1 }"
            @click="seccion = 1"
          ></div>
          <div
            class="popup-bot-selector"
            :class="{ 'popup-bot-selector-active': seccion == 2 }"
            @click="seccion = 2"
          ></div>
        </div>
        <button
          v-show="!guardando"
          class="button button-primary popup-btn-continuar"
          @click="continuar()"
        >
          {{ seccion == 1 ? "Continuar" : "Finalizar" }}
        </button>
        <button v-show="guardando" class="button button-ok popup-btn-continuar">
          Finalizando
        </button>
      </div>
    </div>
    <Salir
      :mostrar="mostrarSalir"
      :tipo="titulo.toLowerCase()"
      @cerrar="mostrarSalir = false"
      @aceptar="cerrar()"
    >
    </Salir>
  </div>
</template>

<script>
import DropdownMultiselect from "@/components/DropdownMultiselect.vue";
import Dropdown from "@/components/Dropdown.vue";
import Salir from "@/components/Salir.vue";
import { mapGetters } from "vuex";

export default {
  name: "Nuevo_Usuario",
  components: { Dropdown, DropdownMultiselect, Salir },
  data() {
    return {
      titulo: "Crear nuevo usuario",
      permisos: [],
      empresas: [],
      vigilancias: [],
      seccion: 1,
      permisosElegidos: [],
      empresasElegidas: [],
      vigilanciasElegidas: [],
      nombre: "",
      apellido: "",
      email: "",
      dni: "",
      id: null,
      errorPermiso: false,
      errorEmpresa: false,
      errorNombre: false,
      errorApellido: false,
      errorVigilancia: false,
      errorEmail: "",
      errorDNI: false,
      guardando: false,
      mostrarSalir: false,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {
    this.$emitter.on("usuarios-editar", (usuario) => this.editar(usuario));
    this.getEmpresas();
    this.$emitter.on("update", () => {
      this.getRoles();
    });
    if (this.usuario != null) {
      this.getRoles();
    }
  },
  beforeUnmount() {
    this.$emitter.off("usuarios-editar");
  },
  methods: {
    onlyNumbers($event) {
      var key = $event.currentTarget.value.substr(
        $event.currentTarget.value.length - 1,
        1
      );
      if (
        key != "0" &&
        key != "1" &&
        key != "2" &&
        key != "3" &&
        key != "4" &&
        key != "5" &&
        key != "6" &&
        key != "7" &&
        key != "8" &&
        key != "9"
      ) {
        $event.currentTarget.value = $event.currentTarget.value.substr(
          0,
          $event.currentTarget.value.length - 1
        );
      }
    },
    onlyNumbersPress($event) {
      var key = $event.key;
      if (
        key != "0" &&
        key != "1" &&
        key != "2" &&
        key != "3" &&
        key != "4" &&
        key != "5" &&
        key != "6" &&
        key != "7" &&
        key != "8" &&
        key != "9"
      ) {
        $event.preventDefault();
      }
    },
    updateDropdownPermiso(payload) {
      this.permisosElegidos = [];
      this.permisosElegidos.push(payload.rol);
    },
    updateDropdownEmpresa(payload) {
      this.empresasElegidas = [];
      let vigilancias = [];
      payload.forEach((p) => {
        this.empresasElegidas.push(p.id);
        p.vigilancias.forEach((v) => {
          if (!vigilancias.some((v2) => v2.id == v.id)) {
            vigilancias.push(v);
          }
        });
      });
      this.vigilancias = vigilancias;
      this.vigilancias.push({ nombre: "Todas" });
    },
    updateDropdownVigilancia(payload) {
      this.vigilanciasElegidas = [];
      payload.forEach((p) => {
        this.vigilanciasElegidas.push(p.id);
      });
    },
    getRoles() {
      var that = this;
      that.permisos = [];
      this.$axios
        .get(this.$serverURL + "/api/user/rol/")
        .then(function (response) {
          if (that.usuario.rol.some((r) => r == "supervisor_general")) {
            response.data = response.data.slice(2, response.data.length);
          } else if (that.usuario.rol.some((r) => r == "intendente")) {
            response.data = response.data.slice(3, response.data.length);
          }
          response.data.forEach((p) => {
            let rol = p.split("_").join(" ");
            that.permisos.push({
              name: rol[0].toUpperCase() + rol.substring(1),
              rol: p,
            });
          });
          console.log("roles", that.permisos);
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getEmpresas() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/empresas/all/")
        .then(function (response) {
          console.log("empresas", response.data);
          that.empresas = response.data;
          that.empresas.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    editar(usuario) {
      this.titulo = "Editar usuario";
      this.id = usuario.id;
      this.nombre = usuario.nombre;
      this.apellido = usuario.apellido;
      this.email = usuario.email;
      this.dni = usuario.dni;
      this.$refs.dropdownEmpresa.selectedOptions = this.empresas.filter((e) =>
        usuario.empresas.some((e2) => e2.id == e.id)
      );
      this.$refs.dropdownEmpresa.$refs.dropdownInput2.value =
        usuario.empresas.length + " seleccionados";
      this.$refs.dropdownVigilancia.selectedOptions = usuario.vigilancias;
      this.$refs.dropdownVigilancia.$refs.dropdownInput2.value =
        usuario.vigilancias.length + " seleccionados";
      this.$refs.dropdownPermiso.selectedOption = this.permisos.filter(
        (p) => usuario.rol[0] == p.rol
      )[0];
      this.$refs.dropdownVigilancia.placeholderText1 =
        usuario.vigilancias.length + " seleccionados";
      this.$refs.dropdownVigilancia.placeholderText2 =
        usuario.vigilancias.length + " seleccionados";

      this.$refs.dropdownPermiso.$refs.dropdownInput2.value = usuario.rol[0];

      this.empresasElegidas = usuario.empresas.map((e) => e.id);
      this.vigilanciasElegidas = usuario.vigilancias.map((v) => v.id);
      this.permisosElegidos = usuario.rol;

      let vigilancias = [];
      this.empresas
        .filter((e) => usuario.empresas.some((e2) => e2.id == e.id))
        .forEach((e) => {
          e.vigilancias.forEach((v) => {
            if (!vigilancias.some((v2) => v2.id == v.id)) {
              vigilancias.push(v);
            }
          });
        });
      this.vigilancias = vigilancias;
      this.vigilancias.push({ nombre: "Todas" });
    },
    continuar() {
      if (this.seccion == 1) {
        this.seccion = 2;
      } else {
        this.finalizar();
      }
    },
    finalizar() {
      this.guardando = true;
      let continuar = true;

      if (this.permisosElegidos.length == 0) {
        this.errorPermiso = true;
        continuar = false;
      } else {
        this.errorPermiso = false;
      }

      if (this.empresasElegidas == null) {
        this.errorEmpresa = true;
        continuar = false;
      } else {
        this.errorEmpresa = false;
      }

      if (this.vigilanciasElegidas == null) {
        this.errorVigilancia = true;
        continuar = false;
      } else {
        this.errorVigilancia = false;
      }

      if (!continuar) {
        this.guardando = false;
        this.seccion = 1;
      } else {
        if (this.nombre == "") {
          this.errorNombre = true;
          continuar = false;
        } else {
          this.errorNombre = false;
        }

        if (this.apellido == "") {
          this.errorApellido = true;
          continuar = false;
        } else {
          this.errorApellido = false;
        }

        if (this.email == "" || !this.validateEmail(this.email)) {
          this.errorEmail = "Email inválido";
          continuar = false;
        } else {
          this.errorEmail = "";
        }

        if (this.dni == "") {
          this.errorDNI = true;
          continuar = false;
        } else {
          this.errorDNI = false;
        }

        if (continuar) {
          let json = {
            id: this.id,
            nombre: this.nombre,
            apellido: this.apellido,
            email: this.email,
            dni: this.dni,
            rol: this.permisosElegidos,
            empresas: this.empresasElegidas,
            vigilancias: this.vigilanciasElegidas,
          };
          var that = this;
          if (this.id != null) {
            this.$axios
              .put(this.$serverURL + "/api/user/" + this.id + "/", json)
              .then(function (response) {
                console.log(response);
                that.guardando = false;
                that.$emit("get");
                that.cerrar();
              })
              .catch(function (response) {
                console.log(response);
                that.guardando = false;
                that.$emit("get");
                Object.values(response.response.data).forEach((d) =>
                  that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
                );
              });
          } else {
            console.log(json);
            this.$axios
              .post(this.$serverURL + "/api/user/", json)
              .then(function (response) {
                console.log(response);
                that.guardando = false;
                that.$emit("get");
                that.cerrar();
              })
              .catch(function (response) {
                console.log(response);
                console.log(response.response.data);
                that.guardando = false;
                that.$emit("get");
                Object.values(response.response.data).forEach((d) =>
                  that.$emitter.emit("toast-error", d.length == 1 ? d[0] : d)
                );
              });
          }
        } else {
          this.guardando = false;
        }
      }
    },
    cerrar() {
      this.$emit("cerrar");
      this.titulo = "Crear nuevo usuario";
      this.limpiar();
      this.seccion = 1;
      this.mostrarSalir = false;
    },
    limpiar() {
      this.permisosElegidos = [];
      this.empresasElegidas = [];
      this.vigilanciasElegidas = [];
      this.vigilancias = [];
      this.nombre = "";
      this.apellido = "";
      this.email = "";
      this.dni = "";
      this.id = null;
      this.$refs.dropdownEmpresa.selectedOptions = [];
      this.$refs.dropdownEmpresa.$refs.dropdownInput2.value = "Selecciona";
      this.$refs.dropdownVigilancia.selectedOptions = [];
      this.$refs.dropdownVigilancia.$refs.dropdownInput2.value = "Selecciona";
      this.$refs.dropdownPermiso.selectedOption = [];
      this.$refs.dropdownPermiso.$refs.dropdownInput2.value = "Selecciona";
    },
    validateEmail(email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
