<template>
  <div class="view">
    <Topbar :seccion="'usuarios'"></Topbar>
    <div class="view-container">
      <div class="secciones-top">
        <label class="secciones-title">Usuarios</label>
        <Dropdown
          class="dropdown"
          :options="empresas"
          :placeholder="'Todas las empresas'"
          :paraArriba="false"
          :conInput="false"
          :name="'nombre'"
          v-on:updateOption="updateDropdownEmpresa"
        ></Dropdown>
        <Dropdown
          class="dropdown"
          :options="permisos"
          :placeholder="'Posición'"
          :paraArriba="false"
          :conInput="false"
          :name="'name'"
          :imagen="'navbar/usuarios-active.png'"
          v-on:updateOption="updateDropdownRol"
        ></Dropdown>
        <div
          class="secciones-buscar"
          :class="{ 'secciones-buscar-active': searchText != '' }"
        >
          <input
            type="text"
            placeholder="Buscar"
            v-model="searchText"
            @keyup="filtrar()"
          />
          <img
            class="secciones-buscar-close"
            src="../../assets/img/icon/close.png"
            @click="resetBuscar()"
          />
          <img
            class="secciones-buscar-img"
            src="../../assets/img/icon/lupa.png"
          />
        </div>
      </div>
      <div class="secciones-usuarios">
        <div
          v-for="usuario in usuariosFiltrados"
          :key="usuario.id"
          class="usuarios-usuario"
          @click="elegirUsuario(usuario)"
        >
          <img
            class="usuario-verificado"
            src="../../assets/img/icon/verificado.png"
          />
          <label class="usuario-rol">{{ getRolesText(usuario) }}</label>
          <img
            class="usuario-img"
            src="../../assets/img/login/supervisor.png"
          />
          <label class="usuario-nombre">{{
            usuario.nombre + " " + usuario.apellido
          }}</label>
          <label class="usuario-dni">DNI {{ usuario.dni }}</label>
          <label class="usuario-empresa">{{ getEmpresasText(usuario) }}</label>
        </div>
      </div>
      <button
        class="button button-primary secciones-crear-btn"
        @click="mostrarNuevo = true"
        v-if="usuario != null && !usuario.rol.some((r) => r == 'supervisor')"
      >
        <img src="../../assets/img/icon/usuarios_white.png" />Crear nuevo
        usuario
      </button>
    </div>
    <Nuevo
      v-show="mostrarNuevo"
      @cerrar="cerrarNuevo()"
      @get="getUsuarios()"
    ></Nuevo>
    <Info
      v-if="mostrarInfo"
      :usuario="usuarioElegido"
      :empresas="empresas"
      :vigilancias="vigilancias"
      @cerrar="cerrarInfo()"
      @editar="editar"
      @get="getUsuarios()"
    >
    </Info>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Dropdown from "@/components/Dropdown.vue";
import Nuevo from "@/components/admin/usuarios/Nuevo.vue";
import Info from "@/components/admin/usuarios/Info.vue";
import { mapGetters } from "vuex";

export default {
  name: "Usuarios",
  components: { Topbar, Dropdown, Nuevo, Info },
  data() {
    return {
      empresas: [],
      vigilancias: [],
      permisos: [],
      searchText: "",
      usuarios: [],
      usuariosFiltrados: [],
      filtroEmpresa: "Todas",
      filtroRol: "Todos",
      mostrarNuevo: false,
      mostrarInfo: false,
      usuarioElegido: null,
    };
  },
  computed: {
    ...mapGetters(["usuario"]),
  },
  mounted() {
    this.getRoles();
    this.getEmpresas();
    this.getVigilancias();
  },
  methods: {
    updateDropdownEmpresa(payload) {
      if (payload.nombre == "Todas") {
        this.filtroEmpresa = payload.nombre;
      } else {
        this.filtroEmpresa = payload.id;
      }
      this.filtrar();
    },
    updateDropdownRol(payload) {
      if (payload.name == "Todos") {
        this.filtroRol = "Todos";
      } else {
        this.filtroRol = payload.rol;
      }
      this.filtrar();
    },
    resetBuscar() {
      this.searchText = "";
      this.filtrar();
    },
    filtrar() {
      let filtroBuscar = this.searchText.toLowerCase();

      this.usuariosFiltrados = this.usuarios.filter(
        (u) =>
          (this.filtroRol == "Todos" ||
            (u.rol != null && u.rol.some((r) => r == this.filtroRol))) &&
          (this.filtroEmpresa == "Todas" ||
            (u.empresas != null &&
              u.empresas.some((e) => e.id == this.filtroEmpresa))) &&
          (this.searchText == "" ||
            (u.nombre != null &&
              u.nombre.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (u.apellido != null &&
              u.apellido.toLowerCase().indexOf(filtroBuscar) > -1) ||
            (u.dni != null && u.dni.toString().indexOf(filtroBuscar) > -1))
      );
    },
    cerrarNuevo() {
      this.mostrarNuevo = false;
    },
    cerrarInfo() {
      this.mostrarInfo = false;
    },
    getRolesText(usuario) {
      let respuesta = "";
      if (usuario.rol != null) {
        usuario.rol.forEach((p) => {
          let rol = p.split("_").join(" ");
          respuesta =
            respuesta + rol[0].toUpperCase() + rol.substring(1) + ", ";
        });
      }
      return respuesta.substr(0, respuesta.length - 2);
    },
    elegirUsuario(usuario) {
      this.usuarioElegido = usuario;
      this.mostrarInfo = true;
    },
    editar(usuario) {
      this.mostrarNuevo = true;
      this.$emitter.emit("usuarios-editar", usuario);
    },
    getUsuarios() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/user/all/")
        .then(function (response) {
          console.log("usuarios", response);
          that.usuarios = response.data;
          that.usuariosFiltrados = response.data;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getRoles() {
      var that = this;
      that.permisos = [];
      this.$axios
        .get(this.$serverURL + "/api/user/rol/")
        .then(function (response) {
          response.data.forEach((p) => {
            let rol = p.split("_").join(" ");
            that.permisos.push({
              name: rol[0].toUpperCase() + rol.substring(1),
              rol: p,
            });
          });
          that.permisos.push({ name: "Todos" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getEmpresas() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/empresas/all/")
        .then(function (response) {
          that.getVigilancias();
          that.empresas = response.data;
          that.empresas.push({ nombre: "Todas" });
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getVigilancias() {
      var that = this;
      this.$axios
        .get(this.$serverURL + "/api/vigilancias/all/")
        .then(function (response) {
          that.getUsuarios();
          that.vigilancias = response.data;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    getEmpresasText(usuario) {
      if (usuario.empresas.length == this.empresas.length - 1) {
        return "Todas las empresas";
      } else {
        let result = "";
        if (usuario.empresas.length == 1) {
          result = usuario.empresas[0].nombre;
        } else {
          result = usuario.empresas.length + " empresas";
        }
        return result;
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/views/secciones.scss">
</style>
<style scoped lang="scss" src="@/assets/css/views/admin/usuarios.scss">
</style>
